import React from "react"
import styled from "styled-components"

import SEO from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import { useTranslateValue } from "../../data/TranslateContext"

const PrivacyPolicyPageWrapper = styled(PageWrapper)`
  padding-top: 4rem;

  color: ${props => props.theme.color.primary};
`

export default function PrivacyPolicyPage() {
  const [{ language }] = useTranslateValue()

  return (
    <PrivacyPolicyPageWrapper>
      <SEO title="Privacy Policy" />
      <Row>
        {language === "en" && (
          <>
            <h1>Privacy Policy</h1>

            <h2>f'real foods Respects Your Privacy</h2>
            <p>
              This notice will explain the practices of f'real foods with
              respect to any information collected from you while using
              freal.avalaunch0.com (the "Site") or subscribing to any f'real
              foods newsletter. By submitting your information to f'real foods
              or using the Site, you agree to the collection and use of your
              information as described herein. We reserve the right to change
              these terms and will post any changes on this page, so check back
              regularly. Your continued use of the Site following any changes
              will constitute your acceptance of such changes.
            </p>

            <h2>Information We Collect</h2>
            <p>
              On some pages on the Site, you can voluntarily provide personally
              identifiable information such as your first and last name, email
              address and ZIP code. You also have the option to provide certain
              demographic information (such as age and gender) to us so that we
              can learn more about our customers. When you send a text to f'real
              foods, we will store your telephone number.
            </p>
            <p>
              We do not knowingly collect personally identifiable information
              from visitors to the Site who are under thirteen years of age.
            </p>

            <h2>How We Use Information</h2>
            <p>
              Except as disclosed in this notice, we do not sell or disclose
              your personally identifiable information to third parties. We may
              access, use or disclose your personally identifiable information
              to provide you with our electronic newsletter and promotional
              offers by email or text; operate, maintain, service or improve the
              Site; or for other general internal business purposes.
            </p>
            <p>
              We may use third-party contractors to help us operate our business
              and the Site and may share your personally identifiable
              information with these contractors for those limited purposes.
            </p>
            <p>
              We may use non-identifying and aggregate information about the
              Site and its users for internal purposes and we may share it with
              third parties to illustrate trends and user demographics.
            </p>
            <p>
              If f'real foods is sold to or merges with another company, some or
              all of the information collected from you may be transferred to a
              third party as a result of the transfer of assets.
            </p>

            <h2>Disclaimer</h2>
            <p>
              We reserve the right to disclose your personally identifiable
              information as required by law and when we believe that disclosure
              is necessary to protect our rights and/or to comply with a
              judicial proceeding, court order, or legal process served on our
              Web site.
            </p>

            <h2>Opt-out</h2>
            <p>
              If you no longer wish to receive our newsletter and promotional
              communications, you may opt-out of receiving them by following the
              instructions included in each newsletter or communication or by
              emailing us at realpeople@freal.com. We also offer an opportunity
              to opt-out of these communications at the time you first provide
              your email address.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions or suggestions regarding our privacy
              policy, please contact us.
            </p>

            <h2>
              <a
                href="https://www.dol.gov/ofccp/regs/compliance/posters/ofccpost.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                EEO Poster
              </a>
            </h2>
            <p>
              F'real Foods Inc. ("f'real") is committed to equal opportunity for
              all, without regard to race, religion, color, national origin,
              citizenship, sex, age, veteran status, disability, genetic
              information, or any other protected characteristic. F'real will
              make reasonable accommodations for known physical or mental
              limitations of otherwise qualified employees and applicants with
              disabilities unless the accommodation would impose an undue
              hardship on the operation of our business. If you are interested
              in applying for an employment opportunity and feel you need a
              reasonable accommodation pursuant to the ADA, please contact us at
              1-877 FOR REAL (367 7325).
            </p>
          </>
        )}

        {language === "fr" && (
          <>
            <h1>Politique de confidentialité</h1>

            <h2>f’real foods respecte votre vie privée</h2>
            <p>
              Le présent avis explique les pratiques de f’real foods en ce qui
              concerne les renseignements qu’elle recueille lorsque vous
              utilisez le site freal.avalaunch0.com (le « site ») ou que vous
              vous abonnez à un bulletin d’information de f’real foods. En
              soumettant des renseignements à f’real foods ou en utilisant le
              site, vous acceptez que vos renseignements soient recueillis de la
              manière et utilisés aux fins qui sont décrites aux présentes. Nous
              nous réservons le droit d’apporter des modifications aux présentes
              conditions, modifications que nous afficherons sur la présente
              page; nous vous invitons donc à la consulter régulièrement. En
              continuant d’utiliser le site après que des modifications ont été
              apportées aux présentes conditions, vous signifiez que vous les
              acceptez.
            </p>

            <h2>Renseignements que nous recueillons</h2>
            <p>
              Dans certaines pages du site, vous pouvez fournir volontairement
              des renseignements personnels, tels que vos nom et prénom, votre
              adresse électronique et votre code postal. Vous avez également la
              possibilité de nous fournir certains renseignements à caractère
              démographique (tels que votre âge et votre sexe) afin que nous
              puissions en savoir plus sur notre clientèle. Lorsque vous nous
              envoyez un message texte, nous enregistrons votre numéro de
              téléphone.
            </p>
            <p>
              Nous ne recueillons pas sciemment des renseignements personnels
              auprès d’utilisateurs du site âgés de moins de treize ans.
            </p>

            <h2>Comment nous utilisons les renseignements</h2>
            <p>
              Sous réserve de ce qui est indiqué dans le présent avis, nous ne
              vendons ni ne communiquons aucun renseignement personnel à des
              tiers. Nous pourrions consulter vos renseignements personnels, les
              utiliser ou les communiquer pour vous envoyer notre bulletin
              d’information électronique et nos offres promotionnelles par
              courriel ou par message texte, pour exploiter, maintenir,
              entretenir ou améliorer le site, ou pour d’autres fins
              commerciales internes générales.
            </p>
            <p>
              Nous pourrions faire appel à des sous-traitants tiers pour nous
              aider à exploiter notre entreprise et le site, et pourrions leur
              communiquer vos renseignements personnels aux fins limitées
              mentionnées ci-dessus.
            </p>
            <p>
              Nous pourrions utiliser des renseignements agrégés non personnels
              portant sur le site et ses utilisateurs à des fins internes et
              nous pourrions les communiquer à des tiers pour illustrer les
              tendances et les données démographiques des utilisateurs.
            </p>
            <p>
              Si f’real foods est vendue ou si f’real foods et une autre société
              fusionnent, les renseignements recueillis auprès de vous
              pourraient être transférés en tout ou en partie à un tiers à la
              suite du transfert d’actifs.
            </p>

            <h2>Avis de non-responsabilité</h2>
            <p>
              Nous nous réservons le droit de communiquer vos renseignements
              personnels si la loi l’exige et si nous pensons qu’il est
              nécessaire de le faire pour protéger nos droits et/ou pour nous
              conformer à une instance judiciaire, à une ordonnance d’un
              tribunal ou à une déclaration d’exploitation légale de données
              visant notre site Web.
            </p>

            <h2>Option de sortie</h2>
            <p>
              Si vous ne souhaitez plus recevoir notre bulletin d’information et
              nos communications promotionnelles, vous pouvez choisir de ne plus
              les recevoir en suivant les instructions indiquées dans chaque
              bulletin d’information ou communication ou en nous envoyant un
              courriel à l’adresse realpeople@freal.com. Nous vous offrons
              également la possibilité de refuser de recevoir ces communications
              lorsque vous fournissez votre adresse électronique pour la
              première fois.
            </p>

            <h2>Communiquez avec nous</h2>
            <p>
              Si vous avez des questions ou des suggestions concernant notre
              politique de confidentialité, veuillez communiquer avec nous.
            </p>

            <h2>Affiche Égalité des chances face à l’emploi</h2>
            <p>
              F’real Foods Inc. (« f’real ») s’engage à offrir des chances
              égales à tous, sans égard à la race, à la religion, à la couleur,
              à l’origine nationale, à la citoyenneté, au sexe, à l’âge, au
              statut de vétéran, au handicap, à l’information génétique ou à
              toute autre caractéristique protégée. f’real s’engage à prendre
              des mesures raisonnables d’adaptation pour les limitations
              physiques ou mentales connues des employés et des candidats
              handicapés par ailleurs qualifiés, à moins que ces mesures
              n’imposent une contrainte excessive à l’exploitation de notre
              entreprise. Si vous souhaitez vous porter candidat à un emploi et
              pensez avoir besoin d’une mesure raisonnable d’adaptation,
              conformément à loi américaine intitulée Americans With
              Disabilities Act, veuillez communiquer avec nous au 1 877 FOR REAL
              (367-7325).
            </p>
          </>
        )}
      </Row>
    </PrivacyPolicyPageWrapper>
  )
}
